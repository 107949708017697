import { Component, OnInit } from '@angular/core';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  isdepartment:boolean=false;
  iscategory:boolean=false;
  issubcategory:boolean=false;
  isbrand:boolean=false;
  isproduct:boolean=false;
  isuom:boolean=false;
  isstatus:boolean=false;
  istaxgroup:boolean=false;
  isfoodsymbol:boolean=false;
  iscertificate:boolean=false;
  isspecificationtype:boolean=false;
  iscontentmanagement:boolean=false;
  isproductsection:boolean=false;
  isproductgroup:boolean=false;
  isproductgrouping:boolean=false;
  iscoupon:boolean=false;  
  ispaymentmode:boolean=false;
  iswarehouse:boolean=false;
  iscity:boolean=false;
  isarea:boolean=false;
  isvendor:boolean=false;
  isbranch:boolean=false;
  isuser:boolean=false;
  isuserrole:boolean=false;
  isdeliverytype:boolean=false;
  isdeliveryslot:boolean=false;
  isdeliveryboy:boolean=false;
  isdeliverycharges:boolean=false;
  isbanner:boolean=false;
  ispage:boolean=false;
  isorder:boolean=false;
  isshipment:boolean=true;
  iscustomer:boolean=false;
  isloyalty:boolean=false;
  iswallet:boolean=false;
  isoffer:boolean=false;
  issettings:boolean=false;
  ispricechange:boolean=false;
  issettlement:boolean=false;
  isnotification:boolean=false;
  isrefund:boolean=false;
  iscart:boolean=false;
  issize:boolean=false;
  iscolor:boolean=false;
  isproductrequest:boolean=false;

  isrptbillsummary:boolean=false;
  isrptactivitylog:boolean=false;
  isrptsalessummary:boolean=false;
  isrptproductbycustomer: boolean = false;
  isrptsalesbycustomer: boolean = false;
  isrptsalesbyproduct:boolean=false;
  isrptsalesbybrand:boolean=false;
  isrptcancellproduct: boolean = false;
  isrptfeedback:boolean=false;
  isrptsalesmargin:boolean=false;
  isrptsalestransaction: boolean = false;
  isrptpaymentreceived: boolean = false;
  

  iscatalogmenu:boolean=false;
  isordermenu:boolean=false; 



  isaccountmenu:boolean=false;
  isproductmenu:boolean=false;
  isdeliverymenu:boolean=false;
  isadminmenu:boolean=false;
  iscmsmenu:boolean=false;
  iscrmmenu:boolean=false;
  isoffermenu:boolean=false;
  isnotificationmenu:boolean=false;
  isbannermenu:boolean=false;
  isreportmenu:boolean=false; 

  

  constructor(private loginService: LoginDataService,public router: Router) {  
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    this.userrolerightsColl = this.loginService.userrolerightsColl; 
    this.loginService.serviceloginChange.subscribe((value) => {
      this.userView = value                 
      if(this.userView.userBO){                
        this.userBO= this.userView.userBO                
      }
    
      this.fnCheckMenu();
      
    }); 
  }

  ngOnInit() {
    this.loginService.fnGetUserRoleList();

    

  }


  fnCheckMenu() {

    if (this.loginService.fnCheckUserRoleAll('Department')) {
      this.isdepartment = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Category')) {
      this.iscategory = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Subcategory')) {
      this.issubcategory = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Brand')) {
      this.isbrand = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Product')) {
      this.isproduct = true;
    }
    if (this.loginService.fnCheckUserRoleAll('UOM')) {
      this.isuom = true;
    }
    if (this.loginService.fnCheckUserRoleAll('ProductGroup')) {
      this.isproductgroup = true;
    }

    if (this.loginService.fnCheckUserRoleAll('ProductGrouping')) {
      this.isproductgrouping = true;
    }
    
    if (this.loginService.fnCheckUserRoleAll('Status')) {
      this.isstatus = true;
    }
    if (this.loginService.fnCheckUserRoleAll('TaxGroup')) {
      this.istaxgroup = true;
    }
    if (this.loginService.fnCheckUserRoleAll('FoodSymbol')) {
      this.isfoodsymbol = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Certificate')) {
      this.iscertificate = true;
    }
    if (this.loginService.fnCheckUserRoleAll('SpecificationType')) {
      this.isspecificationtype = true;
    }
    if (this.loginService.fnCheckUserRoleAll('ContentManagement')) {
      this.iscontentmanagement = true;
    }
    if (this.loginService.fnCheckUserRoleAll('ProductSection')) {
      this.isproductsection = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Coupon')) {
      this.iscoupon = true;
    }
    if (this.loginService.fnCheckUserRoleAll('PaymentMode')) {
      this.ispaymentmode = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Notification')) {
      this.isnotification = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Refund')) {
      this.isrefund = true;
    }
    
    
    if (this.loginService.fnCheckUserRoleAll('Warehouse')) {
      this.iswarehouse = true;
    }
    if (this.loginService.fnCheckUserRoleAll('City')) {
      this.iscity = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Area')) {
      this.isarea = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Vendor')) {
      this.isvendor = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Branch')) {
      this.isbranch = true;
    }
    if (this.loginService.fnCheckUserRoleAll('User')) {
      this.isuser = true;
    }
    if (this.loginService.fnCheckUserRoleAll('UserRole')) {
      this.isuserrole = true;
    }
    if (this.loginService.fnCheckUserRoleAll('DeliveryType')) {
      this.isdeliverytype = true;
    }
    if (this.loginService.fnCheckUserRoleAll('DeliverySlot')) {
      this.isdeliveryslot = true;
    }
    if (this.loginService.fnCheckUserRoleAll('DeliveryBoy')) {
      this.isdeliveryboy = true;
    }
    if (this.loginService.fnCheckUserRoleAll('DeliveryCharges')) {
      this.isdeliverycharges = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Banner')) {
      this.isbanner = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Page')) {
      this.ispage = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Order')) {
      this.isorder = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Shipment')) {
      this.isshipment = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Customer')) {
      this.iscustomer = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Loyalty')) {
      this.isloyalty = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Wallet')) {
      this.iswallet = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Offer')) {
      this.isoffer = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Settings')) {
      this.issettings = true;
    }
    if (this.loginService.fnCheckUserRoleAll('PriceChange')) {
      this.ispricechange = true;
    }

    if (this.loginService.fnCheckUserRoleAll('Settlement')) {
      this.issettlement = true;
    }

    if (this.loginService.fnCheckUserRoleAll('BillSummary')) {
      this.isrptbillsummary = true;
    }

    if (this.loginService.fnCheckUserRoleAll('ActivityLog')) {
      this.isrptactivitylog = true;
    }
    
    if (this.loginService.fnCheckUserRoleAll('SalesSummary')) {
      this.isrptsalessummary = true;
    }

   
    if (this.loginService.fnCheckUserRoleAll('SalesByProduct')) {
      this.isrptsalesbyproduct = true;
    }

    
    if (this.loginService.fnCheckUserRoleAll('SalesByBrand')) {
      this.isrptsalesbybrand = true;
    }
    
    if (this.loginService.fnCheckUserRoleAll('SalesByCustomer')) {
      this.isrptsalesbycustomer = true;
    }

    if (this.loginService.fnCheckUserRoleAll('ProductByCustomer')) {
      this.isrptproductbycustomer = true;
    }
    
    if (this.loginService.fnCheckUserRoleAll('SalesMargin')) {
      this.isrptsalesmargin = true;
    }

    if (this.loginService.fnCheckUserRoleAll('SalesTransaction')) {
      this.isrptsalestransaction = true;
    }
    
    if (this.loginService.fnCheckUserRoleAll('PaymentReceived')) {
      this.isrptpaymentreceived = true;
    }

    
      
    if (this.loginService.fnCheckUserRoleAll('CancelledProduct')) {
      this.isrptcancellproduct = true;
    }
    
    if (this.loginService.fnCheckUserRoleAll('Feedback')) {
      this.isrptfeedback = true;
    }
    
    if (this.loginService.fnCheckUserRoleAll('Cart')) {
      this.iscart = true;
    }
    

    if (this.loginService.fnCheckUserRoleAll('Size')) {
      this.issize = true;
    }
    if (this.loginService.fnCheckUserRoleAll('Color')) {
      this.iscolor = true;
    }

    if (this.loginService.fnCheckUserRoleAll('ProductRequest')) {
      this.isproductrequest = true;
    }
    

    
    if (this.isdepartment || this.iscategory || this.issubcategory || this.isbrand || this.isuom ||
      this.istaxgroup || this.isfoodsymbol || this.iscertificate || this.iscolor ||this.issize ||this.isspecificationtype) {
      this.isproductmenu = true;
    }

    if (this.iscontentmanagement || this.isspecificationtype) {
      this.iscmsmenu = true;
    }

    if (this.isdeliverytype || this.isdeliveryslot || this.isdeliveryboy || this.isdeliverycharges || this.isstatus) {
      this.isdeliverymenu = true;
    }

    if (this.isoffer || this.iscoupon || this.ispricechange || this.isproductsection) {
      this.isoffermenu = true;
    }

    if (this.isbanner || this.ispage) {
      this.isbannermenu = true;
    }

    
    if (this.iswarehouse || this.iscity || this.isarea || this.ispaymentmode || this.isvendor || this.isuser || this.isuserrole || this.issettings || this.isbranch) {
      this.isadminmenu = true;
    }


    if (this.isrptbillsummary || this.isrptactivitylog || this.isrptsalessummary || this.isrptproductbycustomer || this.isrptsalesmargin ||
      this.isrptsalesbycustomer || this.isrptsalesbyproduct || this.isrptsalesbybrand || this.isrptcancellproduct || this.isrptfeedback || this.isrptpaymentreceived) {
      this.isreportmenu=true;
    }

    
    if( this.iscustomer || this.isloyalty || this.iscart ){
      this.iscrmmenu=true;
    }

    if(this.isnotification){
      this.isnotificationmenu=true;
    }

    if( this.issettlement || this.isrefund || this.iswallet ){
      this.isaccountmenu=true;
    }

  }


  fnReportMenuClick(type){
    try{      
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'type': type,
        }
        }

      this.router.navigate(['/Report'], navigationExtras);
    }catch(ex){
    
    }
  }
  

}
